<template>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <div class="text-center">
      <div
        class="btn-group-vertical ml-4 mt-4"
        role="group"
        aria-label="Basic example"
      >
        <div class="btn-group">
          <input
            v-model="pin"
            class="text-center form-control-lg mb-4"
            id="code"
          />
        </div>
        <div class="btn-group d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('1')"
          >
            <h3>1</h3>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('2')"
          >
            <h3>2</h3>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('3')"
          >
            <h3>3</h3>
          </button>
        </div>
        <div class="btn-group d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('4')"
          >
            <h3>4</h3>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('5')"
          >
            <h3>5</h3>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('6')"
          >
            <h3>6</h3>
          </button>
        </div>
        <div class="btn-group justify-content-center">
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('7')"
          >
            <h3>7</h3>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('8')"
          >
            <h3>8</h3>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('9')"
          >
            <h3>9</h3>
          </button>
        </div>
        <div class="btn-group text-center">
          <button
            type="button"
            class="btn btn-danger col-4 py-3"
            @click="cutPin()"
          >
            <h4><i class="bi bi-backspace"></i></h4>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary col-4 py-3"
            @click="addPin('0')"
          >
            <h3>0</h3>
          </button>
          <button
            type="button"
            class="btn btn-success col-4 py-3"
            :disabled="loading"
            @click="
              $emit('input', pin);
              pin = '';
            "
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-if="loading"
            ></span>
            <h4 v-if="!loading"><i class="bi bi-check-circle fa-sm"></i></h4>
          </button>
        </div>
      </div>

      <!--
            <div v-for="i in 9" class="col-4 mb-2 mb-sm-2" :key="i">
                <button :disabled="disabled" @click.prevent="pin=pin+i" class="btn btn-number btn-primary w-100 fs-1 py-3">{{i}}</button>
            </div>
            <div class="col-4 mb-3  ">
                <button :disabled="disabled" @click.prevent="pin=pin+0" class="btn btn-number btn-primary w-100 fs-1 py-3">0</button>
            </div>
            <div class="col-4 mb-3 ">
                <button :disabled="disabled" @click="pin=''"  class="btn btn-number btn-danger w-100 fs-1 py-3 d-flex align-items-center justify-content-center"><span class="material-icons fs-ico-big">
                        clear
                    </span></button>
            </div>
            <div class="col-4 mb-3 ">
                <button :disabled="disabled" @click.prevent="$emit('input',pin);pin=''" class="btn btn-number btn-success w-100 fs-1 py-3 d-flex align-items-center justify-content-center"><span class="material-icons fs-1 fs-ico-big">
                        check_circle_outline
                    </span></button>
            </div> -->
    </div>
    <!-- <KeyboardScanner :listen="true" @input="$emit('input',$event);pin=''" :chars="4" :hideinfo="true"></KeyboardScanner> -->
  </div>
</template>
<script>
// import KeyboardScanner from './KeyboardScanner.vue'

export default {
  name: "Pin_pad",
  components: {
    // KeyboardScanner
  },
  props: {
    msg: String,
    disabled: Boolean,
    loading: Boolean,
  },
  data: function () {
    return {
      pin: "",
    };
  },
  methods: {
    cutPin() {
      this.pin = this.pin.slice(0, -1);
    },
    addPin(str) {
      this.pin = this.pin + str;
    },
  },
  mounted() {
    // if(! this.$auth.isAuthenticated()){
    //   alert('musisz sie zalogowac');
    // }else{
    //   alert('jestes zalogowany');
    // }
  },
};
</script>
